import React from "react";
import "./App.css";
import Navbar from "./components/nav/navbar";
import Header from "./components/header/header";
import Card from "./components/common/card";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import VerticalProgressBar from "./components/common/vertical-progress-bar";
import {
  ChartNoAxesColumn,
  CheckCircle2Icon,
  ChevronUp,
  Ellipsis,
  Trophy,
} from "lucide-react";
import ShimmerButton from "./components/ui/shimmer-button";
import { Line, LineChart, ResponsiveContainer, Tooltip } from "recharts";

function App() {
  const currentDate = new Date();
  const options: Intl.DateTimeFormatOptions = { day: "numeric", month: "long" };
  const formattedDate = currentDate.toLocaleDateString("en-US", options);
  const data = [
    { name: "Monday", uv: 400 },
    { name: "Tuesday", uv: 300 },
    { name: "Wednesday", uv: 200 },
    { name: "Thursday", uv: 278 },
    { name: "Friday", uv: 189 },
    { name: "Saturday", uv: 239 },
    { name: "Sunday", uv: 349 },
  ];

  return (
    <div className="flex bg-gray-200 min-h-dvh min-w-fit overflow-hidden dark:bg-gray-900">
      <Navbar />
      <main className="flex-grow">
        <Header />
        <article className="p-8 dark:text-white">
          <div className="flex gap-4 mb-4 flex-wrap">
            <div className="flex-grow">
              <div className="mb-4 flex gap-4 justify-between flex-wrap">
                <Card className="w-60 h-60 flex items-center justify-center">
                  <p className="font-bold text-4xl dark:text-white">Clock</p>
                </Card>
                <Card className="h-60 flex flex-col flex-grow">
                  <h3 className="font-bold text-2xl">Statistics</h3>
                  <div className="flex gap-4 justify-between my-4">
                    <div className="shadow-xl rounded-2xl p-4 dark:bg-gray-800">
                      <p className="text-xs text-gray-400">Correct Answer %</p>
                      <div className="flex items-center gap-2">
                        <p className="font-medium text-2xl">43.50%</p>
                        <div className="px-4 bg-green-200 text-green-400 font-bold rounded-full">
                          <p className="text-sm">+2.45%</p>
                        </div>
                      </div>
                    </div>
                    <div className="shadow-xl rounded-2xl p-4 dark:bg-gray-800">
                      <p className="text-xs text-gray-400">UCAT Score</p>
                      <div className="flex items-center gap-2">
                        <p className="font-medium text-2xl">720</p>
                        <div className="px-4 bg-red-200 text-red-400 font-bold rounded-full">
                          <p className="text-sm">-4.75%</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={data}>
                      <Line type="monotone" dataKey="uv" stroke="#8884d8" />
                      <Tooltip
                        formatter={(value) => [value, "UV"]}
                        labelFormatter={(label) => data[label].name}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Card>
              </div>
              <div className="flex gap-2 flex-wrap">
                <Card className="flex gap-2 flex-grow justify-between">
                  <div>
                    <p className="text-sm">Quantitative</p>
                    <p className="text-2xl">436</p>
                  </div>
                  <div className="flex gap-1 py-1">
                    <VerticalProgressBar progress={80} color="bg-black" />
                    <VerticalProgressBar progress={50} color="bg-black" />
                    <VerticalProgressBar progress={62} color="bg-black" />
                    <VerticalProgressBar progress={90} color="bg-black" />
                    <VerticalProgressBar progress={23} color="bg-black" />
                  </div>
                </Card>
                <Card className="flex-grow">
                  <div>
                    <p className="text-sm">Abstract</p>
                    <p className="text-2xl">824</p>
                  </div>
                  <div></div>
                </Card>
                <Card className="flex-grow">
                  <div className="flex gap-2 items-center">
                    <div className="w-10 h-10 rounded-full bg-gray-200 flex items-center justify-center">
                      <ChartNoAxesColumn size={24} color="black" />
                    </div>
                    <div>
                      <p className="text-sm">Decision</p>
                      <p className="text-2xl">750</p>
                    </div>
                  </div>
                  <div></div>
                </Card>
                <Card className="flex-grow">
                  <div>
                    <p className="text-sm">Situational</p>
                    <p className="text-2xl">452</p>
                  </div>
                  <div></div>
                </Card>
                <Card className="flex-grow">
                  <div className="flex gap-2 items-center">
                    <div className="w-10 h-10 rounded-full bg-gray-200 flex items-center justify-center">
                      <ChartNoAxesColumn size={24} color="black" />
                    </div>
                    <div>
                      <p className="text-sm">Verbal</p>
                      <p className="text-2xl">645</p>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
            <div className="flex gap-4 flex-wrap flex-grow">
              <Card className="flex flex-col flex-grow">
                <h3 className="text-2xl mb-4">Schedule {formattedDate}</h3>
                <div className="*:mb-4">
                  <div className="flex gap-2 last:mb-0">
                    <div className="bg-black w-1 rounded-xl h-12"></div>
                    <div className="pt-1">
                      <p className="font-bold">100 Questions of AR</p>
                      <p className="text-xs text-gray-400">
                        01:00 PM - 02:00 PM
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-2 last:mb-0">
                    <div className="bg-black w-1 rounded-xl h-12"></div>
                    <div className="pt-1">
                      <p className="font-bold">30 Minutes of SJT Questions</p>
                      <p className="text-xs text-gray-400">
                        02:00 PM - 03:00 PM
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-2 last:mb-0">
                    <div className="bg-black w-1 rounded-xl h-12"></div>
                    <div className="pt-1">
                      <p className="font-bold">Mini Mock Practice</p>
                      <p className="text-xs text-gray-400">
                        03:00 PM - 04:00 PM
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-auto flex items-center gap-2 font-bold last:mb-0 justify-end">
                  <p>View all Tasks</p>
                  <ArrowRightIcon />
                </div>
              </Card>
              <Card className="flex flex-col items-center justify-center flex-grow">
                <img
                  src="https://avatar.vercel.sh/james"
                  alt="James"
                  width={50}
                  height={50}
                  className="rounded-full mb-4"
                />
                <strong>Niket Patel</strong>
                <span className="text-gray-300 text-xs whitespace-nowrap">
                  Leeds, United Kingdom
                </span>
              </Card>
            </div>
          </div>
          <div className="flex gap-4 mb-4 flex-wrap">
            <Card className="flex flex-col flex-grow">
              <div className="mb-10 flex gap-8 justify-between">
                <div>
                  <p className="text-xs text-gray-400">Incorrect</p>
                  <p className="text-2xl">258</p>
                  <div className="flex gap-1 items-center text-green-500 font-bold">
                    <CheckCircle2Icon width={15} />
                    <p>On Track</p>
                  </div>
                </div>
                <div>
                  <div className="mb-1 flex text-green-500 font-bold justify-end">
                    <ChevronUp />
                    <p>+2.45%</p>
                  </div>
                  <button className="rounded-full py-2 px-8 shadow-xl dark:bg-gray-900 dark:shadow-gray-900">
                    Try Again
                  </button>
                </div>
              </div>
              <div className="h-full min-h-40 flex gap-4 justify-between px-4">
                <VerticalProgressBar
                  progress={50}
                  color="bg-red-500"
                  width="w-3"
                />
                <VerticalProgressBar
                  progress={30}
                  color="bg-yellow-400"
                  width="w-3"
                />
                <VerticalProgressBar
                  progress={85}
                  color="bg-red-500"
                  width="w-3"
                />
                <VerticalProgressBar
                  progress={45}
                  color="bg-green-500"
                  width="w-3"
                />
                <VerticalProgressBar
                  progress={60}
                  color="bg-yellow-400"
                  width="w-3"
                />
                <VerticalProgressBar
                  progress={95}
                  color="bg-green-500"
                  width="w-3"
                />
                <VerticalProgressBar
                  progress={15}
                  color="bg-red-500"
                  width="w-3"
                />
              </div>
            </Card>
            <Card className="*:mb-4 flex-grow">
              <div className="rounded-2xl shadow-xl p-4 first:mb-8 dark:bg-gray-800">
                <div className="flex justify-between">
                  <p className="text-gray-400 text-xs">Highest UCAT Scores</p>
                  <Ellipsis />
                </div>
                <h3 className="font-bold text-3xl">Leaderboard</h3>
              </div>
              <div className="flex items-center gap-2">
                <img
                  src="https://avatar.vercel.sh/james"
                  alt="Jack"
                  className="rounded-full"
                  width={42}
                  height={42}
                />
                <div>
                  <p className="font-medium">Niket Patel</p>
                  <p className="text-gray-400 text-sm">Today, 16:36</p>
                </div>
                <p className="ml-auto font-bold">900</p>
              </div>
              <div className="flex items-center gap-2">
                <img
                  src="https://avatar.vercel.sh/jack"
                  alt="Jill"
                  className="rounded-full"
                  width={42}
                  height={42}
                />
                <div>
                  <p className="font-medium">Harsh Ojha</p>
                  <p className="text-gray-400 text-sm">Today, 16:36</p>
                </div>
                <p className="ml-auto font-bold">626</p>
              </div>
              <div className="flex items-center gap-2">
                <img
                  src="https://avatar.vercel.sh/jane"
                  alt="John"
                  className="rounded-full"
                  width={42}
                  height={42}
                />
                <div>
                  <p className="font-medium">Moe Baksh</p>
                  <p className="text-gray-400 text-sm">Today, 16:36</p>
                </div>
                <p className="ml-auto font-bold">523</p>
              </div>
            </Card>
            <Card className="*:mb-4 flex flex-col flex-grow">
              <h3 className="font-medium text-xl">Time Spent</h3>
              <div className="flex items-center gap-2">
                <img
                  src="https://avatar.vercel.sh/james"
                  alt="Jack"
                  className="rounded-full"
                  width={42}
                  height={42}
                />
                <div className="mr-2">
                  <p className="font-medium">Niket Patel</p>
                  <p className="text-gray-400 text-sm">Today, 16:36</p>
                </div>
                <p className="ml-auto font-bold">5h</p>
              </div>
              <div className="flex items-center gap-2">
                <img
                  src="https://avatar.vercel.sh/jack"
                  alt="Jill"
                  className="rounded-full"
                  width={42}
                  height={42}
                />
                <div className="mr-2">
                  <p className="font-medium">Harsh Ojha</p>
                  <p className="text-gray-400 text-sm">Today, 16:36</p>
                </div>
                <p className="ml-auto font-bold">3h 34min</p>
              </div>
              <div className="flex items-center gap-2">
                <img
                  src="https://avatar.vercel.sh/jane"
                  alt="John"
                  className="rounded-full"
                  width={42}
                  height={42}
                />
                <div className="mr-2">
                  <p className="font-medium">Moe Baksh</p>
                  <p className="text-gray-400 text-sm">Today, 16:36</p>
                </div>
                <p className="ml-auto font-bold">1h 28min</p>
              </div>
              <div className="mt-auto flex items-center gap-2 font-bold last:mb-0 justify-end">
                <p>View all</p>
                <ArrowRightIcon />
              </div>
            </Card>
          </div>
          <Card>
            <div className="flex gap-4 items-center mb-4">
              <div className="w-14 h-14 rounded-full bg-gray-200 flex items-center justify-center">
                <Trophy size={24} color="black" />
              </div>
              <p className="whitespace-nowrap text-xl font-medium">
                Skills Trainer
              </p>
              <div className="h-1 bg-gray-200 flex-grow rounded shadow-gray-400 shadow-sm"></div>
            </div>
            <div className="flex gap-4 justify-around px-8 flex-wrap lg:flex-nowrap">
              <div className="p-4 flex gap-6 justify-between flex-grow rounded-2xl shadow-xl dark:bg-gray-800">
                <div>
                  <p className="text-2xl font-medium my-4">Reading Fast</p>
                  <p>Description</p>
                </div>
                <div>
                  <img
                    src="https://media.istockphoto.com/id/1416048929/photo/woman-working-on-laptop-online-checking-emails-and-planning-on-the-internet-while-sitting-in.jpg?s=612x612&w=0&k=20&c=mt-Bsap56B_7Lgx1fcLqFVXTeDbIOILVjTdOqrDS54s="
                    width={200}
                    className="rounded-2xl mb-4"
                    alt="Sample"
                  />
                  <ShimmerButton className="mx-auto h-10 dark:text-white">
                    Try Again
                  </ShimmerButton>
                </div>
              </div>
              <div className="p-4 flex gap-6 justify-between flex-grow rounded-2xl shadow-xl dark:bg-gray-800">
                <div>
                  <p className="text-2xl font-medium my-4">Speed Typing</p>
                  <p>Description</p>
                </div>
                <div>
                  <img
                    src="https://media.istockphoto.com/id/1416048929/photo/woman-working-on-laptop-online-checking-emails-and-planning-on-the-internet-while-sitting-in.jpg?s=612x612&w=0&k=20&c=mt-Bsap56B_7Lgx1fcLqFVXTeDbIOILVjTdOqrDS54s="
                    width={200}
                    className="rounded-2xl mb-4"
                    alt="Sample"
                  />
                  <ShimmerButton className="mx-auto h-10 dark:text-white">
                    Try Again
                  </ShimmerButton>
                </div>
              </div>
              <div className="p-4 flex gap-6 justify-between flex-grow rounded-2xl shadow-xl dark:bg-gray-800">
                <div>
                  <p className="text-2xl font-medium my-4">Mental Maths</p>
                  <p>Description</p>
                </div>
                <div>
                  <img
                    src="https://media.istockphoto.com/id/1416048929/photo/woman-working-on-laptop-online-checking-emails-and-planning-on-the-internet-while-sitting-in.jpg?s=612x612&w=0&k=20&c=mt-Bsap56B_7Lgx1fcLqFVXTeDbIOILVjTdOqrDS54s="
                    width={200}
                    className="rounded-2xl mb-4"
                    alt="Sample"
                  />
                  <ShimmerButton className="mx-auto h-10 dark:text-white">
                    Try Again
                  </ShimmerButton>
                </div>
              </div>
            </div>
          </Card>
        </article>
      </main>
    </div>
  );
}

export default App;
