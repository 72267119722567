import React from "react";

interface HeaderUserProps {
  name: string;
  profileImg: string;
}

export default function HeaderUser({ name, profileImg }: HeaderUserProps) {
  const currentDate = new Date();
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const formattedDate = currentDate.toLocaleDateString("en-US", options);

  return (
    <div className="flex gap-2 items-center">
      <img
        src={profileImg}
        alt={name}
        width={50}
        height={50}
        className="rounded-full"
      />
      <div>
        <strong>Hey, {name}</strong>
        <p className="text-gray-400 text-xs whitespace-nowrap">
          {formattedDate}
        </p>
      </div>
    </div>
  );
}
