import React, { JSX, useEffect, useState } from "react";
import NavHeading from "./navbar-heading";
import NavButton from "./navbar-button";
import LogoBlack from "../../logo-black.png";
import LogoWhite from "../../logo-white.png";
import DoctriBlack from "../../doctri-black.png";
import DoctriWhite from "../../doctri-white.png";
import {
  Album,
  BookOpen,
  Brain,
  Calendar,
  ClipboardCheck,
  Globe,
  LayoutDashboard,
  LogOut,
  Settings,
  Shield,
  Timer,
  TrendingUp,
} from "lucide-react";
import { darkThemeEnabled } from "../../lib/utils";
import { log } from "console";

const friends = [
  {
    name: "Harsh Ojha",
    img: "https://avatar.vercel.sh/jack",
    score: Math.floor(Math.random() * 801) - 100,
    lastSeen: new Date(
      Date.now() - Math.floor(Math.random() * 10000000000)
    ).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    }),
  },
  {
    name: "Moe Baksh",
    img: "https://avatar.vercel.sh/jane",
    score: Math.floor(Math.random() * 801) - 100,
    lastSeen: new Date(
      Date.now() - Math.floor(Math.random() * 10000000000)
    ).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
  },
  {
    name: "Niket Patel",
    img: "https://avatar.vercel.sh/james",
    score: Math.floor(Math.random() * 801) - 100,
    lastSeen: new Date(
      Date.now() - Math.floor(Math.random() * 10000000000)
    ).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
  },
];

export default function Navbar() {
  const [logo, setLogo] = useState(LogoBlack);
  const [doctriLogo, setDoctriLogo] = useState(DoctriBlack);

  useEffect(() => {
    const updateLogo = () => {
      const isDarkMode = document.documentElement.classList.contains("dark");
      setLogo(isDarkMode ? LogoWhite : LogoBlack);
      setDoctriLogo(isDarkMode ? DoctriWhite : DoctriBlack);
    };

    // Update logo on initial load
    updateLogo();

    // Listen for changes to the class list of the html element
    const observer = new MutationObserver(updateLogo);
    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ["class"],
    });

    // Cleanup observer on component unmount
    return () => observer.disconnect();
  }, []);

  return (
    <nav className="min-w-64 pl-4 pr-6 flex flex-col">
      <div className="flex gap-1 pt-4">
        <img src={logo} width={50} height={50} alt="Logo" />
        <img src={doctriLogo} width={150} alt="Doctri" />
      </div>
      <br />
      <div className="*:mb-2">
        <NavHeading title="Overview" className="first:mb-4" />
        <NavButton
          title="Dashboard"
          icon={<LayoutDashboard height={20} width={20} />}
          active
        />
        <NavButton
          title="Quantitative Reasoning"
          icon={<TrendingUp height={20} width={20} />}
        />
        <NavButton
          title="Verbal Reasoning"
          icon={<BookOpen height={20} width={20} />}
        />
        <NavButton
          title="Abstract Reasoning"
          icon={<Shield height={20} width={20} />}
        />
        <NavButton
          title="Decision Making"
          icon={<Calendar height={20} width={20} />}
        />
        <NavButton
          title="Situational Judgement"
          icon={<Album height={20} width={20} />}
        />
        <NavButton title="Mini Mocks" icon={<Timer height={20} width={20} />} />
        <NavButton
          title="UCAT Mock Practice"
          icon={<Brain height={20} width={20} />}
        />
        <NavButton title="Community" icon={<Globe height={20} width={20} />} />
        <NavButton
          title="Improvement"
          icon={<ClipboardCheck height={20} width={20} />}
        />
      </div>
      <br />
      <div>
        <NavHeading title="Friends" className="mb-4" />
        {friends.map((review, index) => (
          <div key={index} className="flex justify-between">
            <div className="flex flex-row items-center gap-2 mb-3">
              <img
                className="rounded-full"
                width="32"
                height="32"
                alt=""
                src={review.img}
              />
              <div className="flex flex-col">
                <figcaption className="text-sm font-medium dark:text-white">
                  {review.name}
                </figcaption>
                <p className="text-xs font-medium dark:text-white/40">
                  Today {review.lastSeen}
                </p>
              </div>
            </div>
            <div>
              <p
                className={`${
                  review.score < 0
                    ? "bg-red-200 text-red-500"
                    : "bg-green-200 text-green-500"
                } rounded p-1 text-xs font-bold`}
              >
                {review.score < 0 ? review.score : `+${review.score}`}
              </p>
            </div>
          </div>
        ))}
      </div>
      <br />
      <div className="mt-auto *:mb-4">
        <NavButton
          title="Settings"
          icon={<Settings height={20} width={20} />}
          active
        />
        <NavButton
          title="Log Out"
          icon={<LogOut height={20} width={20} />}
          active
          className="text-red-500"
        />
      </div>
    </nav>
  );
}
