import React from "react";

export default function HeaderSearch() {
  return (
    <input
      type="text"
      placeholder="Search"
      className="p-2 rounded-xl flex-grow outline-none bg-gray-100 text-sm dark:bg-gray-700 dark:text-white"
    />
  );
}
