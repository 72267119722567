import { cn } from "../../lib/utils";
import React from "react";

interface NavHeadingProps {
  title: string;
  className?: string;
}

export default function NavHeading({
  title: text,
  className,
}: NavHeadingProps) {
  return <h2 className={cn("font-medium", className)}>{text}</h2>;
}
