import React from "react";
import HeaderUser from "./header-user";
import HeaderSearch from "./header-search";
import HeaderOptions from "./header-options";

export default function Header() {
  return (
    <header className="flex justify-between items-center gap-4 pt-4 px-4">
      <HeaderUser name="Niket" profileImg="https://avatar.vercel.sh/james" />
      <HeaderSearch />
      <HeaderOptions />
    </header>
  );
}
