import { cn } from "../../lib/utils";
import React from "react";

interface NavButtonProps {
  title: string;
  icon?: React.ReactNode;
  active?: boolean;
  className?: string;
}

export default function NavButton({
  title,
  icon,
  active,
  className,
}: NavButtonProps) {
  return (
    <button
      className={cn(
        `w-full p-2 flex gap-2 font-medium items-center rounded ${
          active
            ? "shadow-gray-400 shadow-md font-bold bg-gray-50 dark:bg-gray-950 dark:shadow-gray-950"
            : ""
        }`,
        className
      )}
    >
      {icon}
      <span>{title}</span>
    </button>
  );
}
